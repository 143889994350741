/*  Copyright © 2017 Arenaanimationraipur All rights Reserved.
 Developed & designed by Suraj Lahre - https://sites.google.com/view/surajlahre/
 Github :: https://github.com/SurajLahre */
:root {
  --themeColor: rgb(224, 9, 9);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  font-family: 'Open Sans', sans-serif;
}

a {
  text-decoration: none;
  color: black;
}

h1 {
  margin-top: 2em;
  font-size: 2em;
}

/* all heading text */
.headingh1text {
  color: #001543;
  font-weight: bolder;
}

.text-center ul li {
  list-style: none;
}


.ded div:nth-child(even) {
  background-color: #646a7a19;

}

/* ================================================================================================= */
/*                    TOP NAVIGATION SECTION START */
/* ================================================================================================= */
.ham-burger-menu {
  transform: translateY(.7em);
  font-size: 1.5em;
  display: block;

}

.navbar {
  display: flex;
  background-color: #ffc107;
  height: 7em;
  z-index: 5;
}

.nav-logo {
  float: left;
  padding-top: .5em;
}

.nav-navlinks {
  background-color: white;
  height: 5em;
  width: 70em;
  border-radius: .5em;
  transform: translateY(3.5em);
  margin: auto;
  cursor: pointer;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.114);

}

.bg-warningh {
  background-color: var(--themeColor);
  color: white;
}

.navbar ul {
  margin: auto;
}

.navbar ul li {
  list-style: none;
  float: left;
  margin: 1em;

}

.nav-social-icons {
  position: absolute;
  right: 3em;
  top: 1em;
  font-size: xx-small;
}

/* FRONT TEXT QUOTE  SECTION START */
.headerimg {
  background-image: url(components/image/ArenaHome.jpg);
  color: white;
  display: flex;
  height: 100vh;
  background-size: cover;
  background-position: center;
  align-items: center;
}


.arenaintroquote {
  color: rgba(255, 255, 255, 1);
  font-size: 48px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.3em;
  letter-spacing: 0.075em;
}

.toptext {
  position: absolute;
  font-size: 13px;
  top: 1em;
  left: 1em;

}

/* ================================================================================================= */
/* .SIDE NAVIGATION HEADER SECTION*/
/* ================================================================================================= */
.side_nav_header {
  background-color: rgb(255, 255, 255);
  box-shadow: 2px 2px 3px 2px rgba(0, 0, 0, 0.107);
  padding: 1em;
  overflow: auto;
  cursor: pointer;
}

.side_nav_header i {
  font-size: x-large;
  float: right;
  cursor: pointer;
}

.side_nav_pannel {
  overflow-x: hidden;
  z-index: 5;
  left: 0;
  width: 0px;
  box-shadow: 2px 2px 3px 4px rgba(0, 0, 0, 0.247);
  height: 100vh;
}

.side_nav_navlist {
  padding: 1em;
}

.side_nav_navlist ul li {
  list-style: none;
  margin: 5px;
  border-bottom: 1px solid;
  padding: 5px;

}

.side_nav_navlist ul li a {
  text-decoration: none;

}

/* ================================================================================================= */
/*                    COURSE LIST SECTION START */
/* ================================================================================================= */

.course-list {
  display: none;
  background-color: rgba(255, 255, 255, 0.998);
  position: fixed;
  padding: 5px;
  border-radius: 5px;
  padding: 1em;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.393);

}

.courseid:hover .course-list {
  display: block;

}

.course-item {

  padding: 1em;
  margin-top: 5px;
  font-size: small;

}

.course-btn {
  text-align: left;
}


.course-item:hover {
  background-color: var(--themeColor);
  color: white;

}

.course-item a:hover {
  color: white;
}


.student-section {
  border-radius: 5px;
  display: none;
  background-color: rgba(255, 255, 255, 0.998);
  position: fixed;
  padding: 1em;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.393);


}

.studentid:hover .student-section {
  display: block;
}


.explore_btn {
  font-weight: 600;

  border-radius: 10rem;
  cursor: pointer;
}

.top-card .card {
  width: 100%;
  height: 60vh;
  min-height: 350px;
  max-height: 400px;
}

.top-card {
  background-color: #4e5e8708;
}

.textbox {
  font-size: 1.5em;
}

#navbarSupportedContent {
  transform: translate(0px, 40px);

}

.paracard {
  color: #646a7a;
}

.team-card-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-auto-rows: minmax(250px, auto);
  grid-auto-columns: minmax(900px, auto);
  row-gap: 3em;
  column-gap: 3em;
  padding: 3em;
}

.teamcard {
  text-align: center;
  border-radius: 9px;
  box-shadow: 0px 3px 10px 0px rgb(0 0 0 / 16%);
  cursor: pointer;
  padding: 1em;
  align-items: center;

}

.teamcard img {
  width: 160px;
  margin: auto;
  text-align: center;
}

.fa-solid-cardicon {
  font-size: 3rem;
}

.grid-row {
  display: flex;

}

.container1 {
  position: relative;
  width: 100%;
}

.image {
  display: block;
  width: 100%;
  height: 100%;
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  width: 100%;
  height: 0%;
  transition: .5s ease;

}

.container1:hover .overlay {
  background-color: #000000b9;
  height: 100%;
}

.text {
  white-space: nowrap;
  color: rgb(255, 255, 255);
  font-size: 20px;
  position: absolute;
  overflow: hidden;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);

}

.card-container-heading {
  flex-wrap: wrap;
}

.topcard {
  transform: translateY(-5em);
  box-sizing: border-box;
  box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.084);
  border-radius: .5em;

}

.ham-burger-menu {
  display: none;
}

.animation-page-text-heading {
  width: 50%;
}

.vidio-container {
  background-color: var(--themeColor);
  position: relative;
}


/*--------------------------------------------------------------
# Testimonials START
--------------------------------------------------------------*/

.testimonial-container {
  background-image: url(components/image/studentfdbck/tesbg.jpg);
  background-size: cover;
  padding-bottom: 5rem;

}

swiper-container {
  width: 1000px;
  height: 300px;
  position: relative;
  left: 10%;
  top: 10%;

}

swiper-slide {
  background-color: var(--themeColor);
  background-position: center;
  background-size: cover;
}

.tes-slider-body {
  text-align: center;
  background-color: var(--themeColor);
}

.ul-list-first {

  border-radius: 5px;
  line-height: 2em;
}

.term-card-body {
  display: flex;
}

.termcard {
  background-position: top;
  margin: auto;
  position: relative;
  border-radius: 15px;
  width: 100%;

  box-shadow: 2px 5px 12px 2px rgba(0, 0, 0, 0.105);
}

.termcard:hover {
  background-color: rgba(255, 0, 0, 0.107);
  cursor: pointer;
  transition: all .3s;
  transform: translateY(-10px);
}

.termcard h4 {
  color: rgb(207, 116, 116);
}

/*--------------------------------------------------------------
  # Testimonials slider END
  --------------------------------------------------------------*/

/*--------------------------------------------------------------
  # CONTACT FORM SECTION START
  --------------------------------------------------------------*/

input[type=text],
input[type=email],
input[type=number],
input[type=tel],
input[type=number],
select,
textarea {
  width: 100%;
  padding: 5px;
  border-radius: 4px;
  margin-top: 1em;
  background-color: transparent;
  border: none;
  outline: solid 1px rgb(0, 0, 0);
  color: rgb(0, 0, 0);


}

select {
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
}


input[type=submit] {
  width: 100%;
  padding: 10px;
  border: none;
  background-color: #000000de;
  color: white;
  cursor: pointer;
  border-radius: 5px;
}

form {
  background-color: rgb(255, 255, 255);
  padding: 1em;
  border-radius: 12px;
  width: 100%;

}

.form-text {
  padding: 1em;
  width: 100%;
}


/* ================================================================================================= */
/* .GalLERY IMAGE VIEWR MODAL */
/* ================================================================================================= */
.modal_container {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 10;
  /* Sit on top */
  top: 0;
  left: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgba(0, 0, 0, 0.297);
  /* Fallback color */
  padding: 2em;

}

.modal_content {
  margin-top: 1em;
  width: 90%;
  height: 100%;
  margin: auto;
  vertical-align: auto;
  box-shadow: 2px 4px 3px 5px rgba(0, 0, 0, 0.135);
}

.GalimageCloser {
  font-size: x-large;
  float: right;
}

.fa-x {
  cursor: pointer;
}

.GalViewCard {
  height: 100%;
}

/* ================================================================================================= */
/*                      FOOTER SECTION START */
/* ================================================================================================= */

.footer {
  background-color: var(--themeColor);
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));

}

.footer-links {
  padding: 1em;
  margin-top: 2em;
  line-height: 2em;

}

.footer-links ul li {
  list-style: none;
}

.footer-links ul li a {
  text-decoration: none;
  color: white;
}

.copyrigh-text {
  background-color: var(--themeColor);
  color: rgba(255, 255, 255, 0.626);

}


.swiper {
  width: 600px;
  height: 300px;
}

/* ================================================================================================= */
/*                      SCROLL TO TOP BUTTON CSS */
/* ================================================================================================= */
.scroll-to-top-button {
  position: fixed;
  bottom: 20px;
  /* Adjust the distance from the bottom as needed */
  right: 20px;
  /* Adjust the distance from the left as needed */
  background-color: #000000;
  /* Button background color */
  color: #fff;
  /* Button text color */
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.3s;
  z-index: 5;
}

.scroll-to-top-button:hover {
  opacity: 1;
}



/* ================================================================================================= */
/*                      MOBILE SND SHORT DEVICE MEDIA QUERRY SECTION START */
/* ================================================================================================= */

@media only screen and (max-width: 910px) {
  .d-flex {
    flex-direction: column;
    margin: auto;
    position: relative;
  }

  h1 {
    margin-top: 0;
  }

  .animation-page-text-heading {
    width: 100%;
  }

  .term-card-body {
    display: flex;
    flex-wrap: wrap;
  }

  .nav-navlinks {
    display: none;
  }

  .nav-logo {
    right: 1em;
    position: absolute;
  }

  .ham-burger-menu {
    display: block;
  }
}

@media (max-width:915px) {
  .nav-navlinks ul {
    display: none;
  }

  .ham-burger-menu {
    position: absolute;
    left: 1em;
    margin: auto;
  }


  .toptext {
    display: none;
  }

}